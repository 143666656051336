import React from 'react'
import classnames from 'classnames'

import closeIcon from '../../icons/close'
import checkIcon from '../../icons/check'
import chevronDownIcon from '../../icons/chevron-down'
import chevronLeftIcon from '../../icons/chevron-left'
import chevronRightIcon from '../../icons/chevron-right'
import circleCheckIcon from '../../icons/circle-check'
import circleChevronLeftIcon from '../../icons/circle-chevron-left'
import circleChevronRightIcon from '../../icons/circle-chevron-right'
import hamburgerMenuIcon from '../../icons/hamburger-menu'
import nextIcon from '../../icons/next'
import playIcon from '../../icons/play'
import pauseIcon from '../../icons/pause'
import previousIcon from '../../icons/previous'
import trashIcon from '../../icons/trash'

import {ReactComponent as closeIcon2} from '../../icons/check.svg'
//import checkIcon from '../../../public/icons/check.svg'
//import chevronDownIcon from '../../../public/icons/chevron-down.svg'
//import chevronLeftIcon from '../../../public/icons/chevron-left.svg'
//import chevronRightIcon from '../../../public/icons/chevron-right.svg'
//import circleCheckIcon from '../../../public/icons/circle-check.svg'
//import circleChevronLeftIcon from '../../../public/icons/circle-chevron-left.svg'
//import circleChevronRightIcon from '../../../public/icons/circle-chevron-right.svg'
//import hamburgerMenuIcon from '../../../public/icons/hamburger-menu.svg'
//import nextIcon from '../../../public/icons/next.svg'
//import playIcon from '../../../public/icons/play.svg'
//import pauseIcon from '../../../public/icons/pause.svg'
//import previousIcon from '../../../public/icons/previous.svg'
//import trashIcon from '../../../public/icons/trash.svg'

import styles from './index.module.scss'

export const icons: Record<
  string,
  React.FunctionComponent<React.SVGProps<SVGElement>>
> = {
  close: closeIcon,
  check: checkIcon,
  chevronDown: chevronDownIcon,
  chevronLeft: chevronLeftIcon,
  chevronRight: chevronRightIcon,
  circleCheck: circleCheckIcon,
  circleChevronLeft: circleChevronLeftIcon,
  circleChevronRight: circleChevronRightIcon,
  hamburgerMenu: hamburgerMenuIcon,
  next: nextIcon,
  play: playIcon,
  pause: pauseIcon,
  previous: previousIcon,
  trash: trashIcon,
  secondCloseIcon: closeIcon2
}

export interface IIconProps extends React.HTMLAttributes<HTMLElement> {
  name: string
  color?: 'primary' | 'secondary' | 'blank' | 'dark'
  size?: 'sm' | 'med' | 'lg' | 'xl' | 'xxl'
}

export function Icon({
  name,
  color,
  className,
  size = 'med',
  ...props
}: IIconProps) {
  if (!Object.prototype.hasOwnProperty.call(icons, name)) {
    return null
  }

  const Component = icons[name]

  return (
    <span
      className={classnames(color && styles[color], className, styles[size])}
      {...props}
    >
      <Component />
    </span>
  )
}
