// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AZ3gY\\+0gve6W0Mp3P3IEzQ\\=\\= table,.Pr71YLdKh1di0oUqrkQawQ\\=\\= table{margin-block:1rem}.AZ3gY\\+0gve6W0Mp3P3IEzQ\\=\\= table th,.Pr71YLdKh1di0oUqrkQawQ\\=\\= table th{border-top:1px solid #000;border-bottom:1px solid #000}.AZ3gY\\+0gve6W0Mp3P3IEzQ\\=\\= td,.Pr71YLdKh1di0oUqrkQawQ\\=\\= td{border-bottom:1px solid #000}.AZ3gY\\+0gve6W0Mp3P3IEzQ\\=\\= tbody tr:hover,.Pr71YLdKh1di0oUqrkQawQ\\=\\= tbody tr:hover{background-color:#000;color:#fff}.AZ3gY\\+0gve6W0Mp3P3IEzQ\\=\\= tbody tr:hover td,.Pr71YLdKh1di0oUqrkQawQ\\=\\= tbody tr:hover td{color:#fff}.AZ3gY\\+0gve6W0Mp3P3IEzQ\\=\\= tbody tr:hover button,.Pr71YLdKh1di0oUqrkQawQ\\=\\= tbody tr:hover button{color:#fff}", "",{"version":3,"sources":["/home/momo/sites/expenses/src/main/homeMedia/index.module.scss"],"names":[],"mappings":"AACE,qEACE,iBAAA,CAGF,2EACE,yBAAA,CACA,4BAAA,CAGF,+DACE,4BAAA,CAGF,uFACE,qBAAA,CACA,UAAA,CAEA,6FACE,UAAA,CAGF,qGACE,UAAA","file":"index.module.scss","sourcesContent":[".moviesContainer, .seriesContainer {\n  & table {\n    margin-block: 1rem;\n  }\n\n  & table th {\n    border-top: 1px solid black;\n    border-bottom: 1px solid black;\n  }\n\n  & td {\n    border-bottom: 1px solid black;\n  }\n\n  & tbody tr:hover {\n    background-color: black;\n    color: white;\n\n    & td {\n      color: white;\n    }\n\n    & button {\n      color: white;\n    }\n  }\n\n}\n"]}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"moviesContainer": "AZ3gY+0gve6W0Mp3P3IEzQ==",
	"seriesContainer": "Pr71YLdKh1di0oUqrkQawQ=="
};
export default ___CSS_LOADER_EXPORT___;
