// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._9XmNFoyfCZD55kjbkj0l1w\\=\\= {\n  padding: 10px;\n  margin: 0 auto;\n  width: 80%;\n}\n\n.u0SgcgNgZ91KfZQfNMgNRg\\=\\= {\n  margin: 20px 0;\n}\n\n.u0SgcgNgZ91KfZQfNMgNRg\\=\\= ul {\n  justify-content: center;\n}\n\n.aB3XQa7a-eEjqHkfxFwuHw\\=\\= {\n  display: grid;\n  grid-template-columns: 2fr 3fr;\n}\n\n._8u2AwVqsmco0B-HL6lGhXw\\=\\= {\n  background-color: var(--color-primary-light);\n}\n\n@media (max-width: 768px) {\n  ._9XmNFoyfCZD55kjbkj0l1w\\=\\= {\n    width: auto;\n  }\n\n  .aB3XQa7a-eEjqHkfxFwuHw\\=\\= {\n    display: flex;\n    flex-direction: column;\n  }\n}\n", "",{"version":3,"sources":["expenses.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,cAAc;EACd,UAAU;AACZ;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,4CAA4C;AAC9C;;AAEA;EACE;IACE,WAAW;EACb;;EAEA;IACE,aAAa;IACb,sBAAsB;EACxB;AACF","file":"expenses.module.css","sourcesContent":[".appContainer {\n  padding: 10px;\n  margin: 0 auto;\n  width: 80%;\n}\n\n.monthsContainer {\n  margin: 20px 0;\n}\n\n.monthsContainer ul {\n  justify-content: center;\n}\n\n.contentContainer {\n  display: grid;\n  grid-template-columns: 2fr 3fr;\n}\n\n.active {\n  background-color: var(--color-primary-light);\n}\n\n@media (max-width: 768px) {\n  .appContainer {\n    width: auto;\n  }\n\n  .contentContainer {\n    display: flex;\n    flex-direction: column;\n  }\n}\n"]}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"appContainer": "_9XmNFoyfCZD55kjbkj0l1w==",
	"monthsContainer": "u0SgcgNgZ91KfZQfNMgNRg==",
	"contentContainer": "aB3XQa7a-eEjqHkfxFwuHw==",
	"active": "_8u2AwVqsmco0B-HL6lGhXw=="
};
export default ___CSS_LOADER_EXPORT___;
