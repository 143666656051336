import React from 'react'
import type {IExpenseDetail} from '.'

import styles from './expenseTotals.module.scss'

export function ExpenseTotals({expenses}: {expenses: IExpenseDetail[]}) {
  const initialTotals = {
    dadNotPaid: 0,
    mumNotPaid: 0,
    jointNotPaid: 0,
    paidTotal: 0,
    notPaidTotal: 0,
    expenseTotal: 0
  }
  const expenseTotals =
    expenses.length > 0
      ? expenses.reduce((acc, value) => {
          return {
            ...acc,
            dadNotPaid:
              !value.paid && value.whoseExpense === 'dad'
                ? acc.dadNotPaid + (+value.amount! || 0)
                : acc.dadNotPaid,
            mumNotPaid:
              !value.paid && value.whoseExpense === 'mum'
                ? acc.mumNotPaid + (+value.amount! || 0)
                : acc.mumNotPaid,
            jointNotPaid:
              !value.paid && value.whoseExpense === 'joint'
                ? acc.jointNotPaid + (+value.amount! || 0)
                : acc.jointNotPaid,
            paidTotal: value.paid
              ? acc.paidTotal + (+value.amount! || 0)
              : acc.paidTotal,
            notPaidTotal: !value.paid
              ? acc.notPaidTotal + (+value.amount! || 0)
              : acc.notPaidTotal,
            expenseTotal: acc.expenseTotal + (+value?.amount! || 0)
          }
        }, initialTotals)
      : initialTotals

  return (
    <div className={styles.expenseTotalContainer}>
      <div className={styles.notPaidTotal}>
        <div>Dad Not Paid</div>
        <span>&pound;{expenseTotals.dadNotPaid.toFixed(2)}</span>
      </div>
      <div className={styles.notPaidTotal}>
        <div>Mum Not Paid</div>
        <span>&pound;{expenseTotals.mumNotPaid.toFixed(2)}</span>
      </div>
      <div className={styles.notPaidTotal}>
        <div>Joint Not Paid</div>
        <span>&pound;{expenseTotals.jointNotPaid.toFixed(2)}</span>
      </div>
      <div className={styles.notPaidTotal}>
        <div>Not Paid Total</div>
        <span>&pound;{expenseTotals.notPaidTotal.toFixed(2)}</span>
      </div>
      <div className={styles.paidTotal}>
        <div>Paid Total </div>
        <span>&pound;{expenseTotals.paidTotal.toFixed(2)}</span>
      </div>
      <div className={styles.expenseTotal}>
        <div>Total </div>
        <span>&pound;{expenseTotals.expenseTotal.toFixed(2)}</span>
      </div>
    </div>
  )
}
