import React, {useState, useEffect} from 'react'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {DataTable, Modal, Pagination} from '@ryusenpai/shared-components'

import {homeMediaApi} from '../../api/homeMediaApi'
import {SearchBar} from '../../common/components/searchBar'
import {seriesColumns} from './constants'
import {Lang} from '../../api/types'

import styles from './index.module.scss'

export function TVSeries() {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [selectedItem, setSelectedItem] = useState<any>(null)
  const [seriesData, setSeriesData] = useState({
    videos: [],
    total: 0,
    page: 1 
  })

  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()
  const pageNumber = new URLSearchParams(location.search).get('page')

  useEffect(() => {
    const fetchList = async () => {
      if (params.lang) {
        const result = await homeMediaApi.getSeries(
          params.lang as Lang,
          pageNumber ? +pageNumber : 1
        )
        //console.log('result: ', result)
        setSeriesData(result)
      }
    }

    fetchList()
  }, [pageNumber])

  const closeModalHandler = () => {
    setModalIsOpen(false)
  }
  const openModalHandler = (id: number) => {
    setModalIsOpen(true)
    setSelectedItem(id)
  }

  const searchHandler = async (e: React.FormEvent, value: string) => {
    e.preventDefault()
    if (!value.trim()) {
      navigate('/home-media/hk/series/?page=1')
    }

    const result = await homeMediaApi.searchSeries(params.lang as Lang, value)
    setSeriesData({
      videos: result.videos || result,
      total: result.total || result.length,
      page: result.page || 1
    })
  }

  const handlePageClick = (index: number) => {
    navigate(`/home-media/hk/series/?page=${index + 1}`)
  }

  const handleDelete = async (id: number) => {
    const result = await homeMediaApi.deleteSeries(params.lang as Lang, id)

    if (result) {
      setModalIsOpen(false)
      setSelectedItem(null)
    }
  }
  console.log('seriesdata: ', seriesData)

  return (
    <>
      <div className={styles.seriesContainer}>
        <SearchBar submitHandler={searchHandler} />
        <DataTable
          data={seriesData.videos}
          columns={seriesColumns(openModalHandler)}
        />
        <div>No. of items: {seriesData.videos.length}</div>
        <Pagination
          total={seriesData.total}
          itemsPerPage={20}
          onClick={handlePageClick}
          selected={seriesData.page - 1}
        />
      </div>
      {modalIsOpen && (
        <Modal
          title="Delete Item"
          content={
            <>
              {selectedItem ? (
                <>
                  <p>{selectedItem?.title}</p>
                  <p>Are you sure want to delete this?</p>
                </>
              ) : (
                <>Error: no selected item</>
              )}
            </>
          }
          onClose={closeModalHandler}
          buttons={[
            {
              label: 'Cancel',
              color: 'secondary',
              onClick: closeModalHandler
            },
            {
              label: 'Confirm',
              color: 'primary',
              onClick: () => handleDelete(selectedItem)
            }
          ]}
        />
      )}
    </>
  )
}
