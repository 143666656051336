import axios from 'axios'
import {config} from '../config'
import {QueryFunctionContext} from 'react-query/types/core/types'
import {Lang} from './types'

const mediaClient = axios.create({
  baseURL: config.homeMediaApiUrl
})

export const homeMediaApi = {
  async getMovies({queryKey}: QueryFunctionContext): Promise<any> {
    const [_, params] = queryKey
    const {yearFrom, yearTo, lang} = params as {
      yearFrom: string
      yearTo: string
      lang: Lang
    }
    const {data} = await mediaClient.get(
      `/movies/${lang}/?yearFrom=${yearFrom}&yearTo=${yearTo}`
    )
    return data
  },

  async deleteMovieEntry(params: {id: number, lang: Lang}) {
    console.log('params: ', params)
    //const [_, id] = queryKey
    const {data} = await mediaClient.delete(`/movies/${params.lang}`, {
      data: {id: params.id}
    })
    return data
  },

  async searchMovies({queryKey}: QueryFunctionContext) {
    const [_, params] = queryKey
    const {searchKeyword, lang} = params as {searchKeyword: string, lang: Lang}
    const {data} = await mediaClient.get(
      `/movies/${lang}/search?searchKeyword=${searchKeyword}`
    )
    return data?.data || []
  },

  async getSeries(lang: Lang, pageNumber: number) {
    const {data} = await mediaClient.get(`/series/${lang}/?page=${pageNumber}`)

    return data?.data || []
  },

  async deleteSeries(lang: Lang, id: number) {
    const {data} = await mediaClient.delete(`/series/${lang}`, {
      data: {id}
    })

    return data
  },

  async searchSeries(lang: Lang, keyword: string) {
    const {data} = await mediaClient.get(`/series/${lang}/search?keyword=${keyword}`)

    return data?.data || []
  }
}
