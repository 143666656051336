import axios from 'axios'
import {config} from '../config'

const userClient = axios.create({
  baseURL: config.userApiUrl,
})

export const userApi = {
  async login(password: string) {
    const {data} = await userClient.post<{token: string}>('/login', {password})

    return data?.token
  },
}
