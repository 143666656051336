import React from 'react'
import {useNavigate} from 'react-router-dom'
import {Button} from '@ryusenpai/shared-components'

import {TextInput} from '../../common/components/textInput'
import {userApi} from '../../api/userApi'
import {storage} from '../../lib/storage'

import styles from './login.module.scss'

export function Login() {
  const [password, setPassword] = React.useState('')

  const navigate = useNavigate()

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const token = await userApi.login(password)
    if (token) {
      storage.set('authToken', token)
      navigate('/expenses')
    }
  }

  return (
    <form onSubmit={handleLogin} className={styles.loginContainer}>
      <TextInput
        label="Enter password"
        type="password"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setPassword(e.target.value)
        }
      />
      <Button type="submit" label="Login" />
    </form>
  )
}
