import React from 'react'

import styles from './index.module.css'

interface ITextInputProps extends React.HTMLProps<HTMLInputElement> {
  label?: string;
  type?: 'text' | 'password'
}

export function TextInput({
  label,
  type = 'text',
  ...props
}: ITextInputProps) {
  return (
    <div className={styles.formRow}>
      {label && <label className={styles.label} htmlFor={label}>{label}</label>}
      <input className={styles.textInput} type={type} {...props} />
    </div>
  )
}
