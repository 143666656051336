import axios from 'axios'
import {IExpenseDetail} from '../main/expenses'
import {config} from '../config'
import {monthNames} from '../main/constants'

import type {
  IExpenseDetailDto,
  IMonthlyTotalResponseDto,
  IMonthlyTotalViewDto
} from './types'

const expenseClient = axios.create({
  baseURL: config.expenseApiUrl,
  timeout: 3000
})

export default {
  async getExpensesForMonth(monthNumber: number, year: number) {
    try {
      const {data} = await expenseClient.get<{data: IExpenseDetailDto[]}>(
        `/month/${monthNumber}/year/${year}`
      )
      //console.log('data: ', data)
      return transformExpensesToViewDto(data.data)
    } catch (e) {
      return {error: 'Unable to fetch data'}
    }
  },

  async getMonthlyTotals(year: number) {
    try {
      const {data} = await expenseClient.get<{
        data: IMonthlyTotalResponseDto[]
      }>(`/all?year=${year}`)
      //console.log('data: ', data)
      return transformMonthlyTotalsToViewDto(data.data)
    } catch (e) {
      return {error: 'Unable to fetch monthly totals'}
    }
  },

  async addExpense(expenseDetail: IExpenseDetail) {
    try {
      const {data} = await expenseClient.put('/add', expenseDetail)
      console.log('add expense response: ', data)
      return {data: transformExpensesToViewDto([data.data])}
    } catch (e) {
      return {error: 'Unable to save expense'}
    }
  },

  async updateExpense(expenseDetail: IExpenseDetail) {
    try {
      const result = await expenseClient.patch('/update', expenseDetail)
      console.log('update expense response: ', result)
      return result
    } catch (e) {
      return {error: 'Unable to update expense'}
    }
  },

  async removeExpense(id: string) {
    console.log('id: ', id)
    await expenseClient.delete('/remove', {data: {id}})
  }
}

function transformExpensesToViewDto(
  expenses: IExpenseDetailDto[]
): IExpenseDetail[] {
  return expenses.map((e) => {
    return {
      id: e.id,
      amount: e.amount,
      date: e.date,
      label: e.label,
      paid: e.paid,
      whoseExpense: e.belongsTo
    }
  })
}

function transformMonthlyTotalsToViewDto(
  totals: IMonthlyTotalResponseDto[]
): IMonthlyTotalViewDto[] {
  return monthNames.map((monthName, index) => {
    const foundMonth = totals.find((t) => t.month === index + 1)

    return {
      month: index + 1,
      name: monthName,
      total: foundMonth?.total || 0,
      dadTotal: foundMonth?.total_dad || 0,
      mumTotal: foundMonth?.total_mum || 0,
      jointTotal: foundMonth?.total_joint || 0
    }
  })
}
