import React from 'react'

import styles from './index.module.css'

interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  label: string
  onClick?: () => void
  backgroundColor?: string
  size?: 'small' | 'medium' | 'large'
  primary?: boolean
}

export function Button({
  label,
  backgroundColor,
  primary = false,
  size = 'medium',
  ...props
}: IButtonProps) {
  const mode = primary
    ? styles['storybook-button--primary']
    : styles['storybook-button--secondary']
  return (
    <button
      type="button"
      className={[
        styles['storybook-button'],
        styles[`storybook-button--${size}`],
        mode
      ].join(' ')}
      style={{backgroundColor}}
      {...props}
    >
      {label}
    </button>
  )
}
