import {add, format} from 'date-fns'

export const storage = {
  set(key: string, value: string) {
    const currentTime = new Date()
    const extraThirtyMins = format(add(currentTime, {minutes: 30}), 'dd/MM/yyyy HH:mm:ss')
    window.sessionStorage.setItem(key, JSON.stringify({value, expiresIn: extraThirtyMins}))
  },

  get(key: string) {
    const value = window.sessionStorage.getItem(key)
    if (!value) {
      return null
    }

    const extractedValue = JSON.parse(value)
    console.log('extractedValue: ', extractedValue)
    const currentFormattedTime = format(new Date(), 'dd/MM/yyyy HH:mm:ss')
    if (extractedValue.expiresIn > currentFormattedTime) {
      return extractedValue.value
    }

    return null
  },

  clear() {
    window.sessionStorage.clear()
  }
}
