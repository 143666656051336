import React, {useState} from 'react'
import classnames from 'classnames'
import {format} from 'date-fns'
import {DataTable, Icon, Modal} from '@ryusenpai/shared-components'
//import PlayIcon from '../../play.svg'

import type {IExpenseDetail} from '../expenses'

import styles from './expenseList.module.scss'

const WARNING_LIMIT = process.env.WARNING_LIMIT || 500
const DANGER_LIMIT = process.env.DANGER_LIMIT || 1000

interface IExpenseListProps {
  expenses: IExpenseDetail[]
  removeCallback: (id: string) => void
  updateCallback: (id: string) => void
}

interface IExpenseColumns {
  field: string
  label: string
  cellRender: (data: IExpenseDetail) => React.ReactNode
}
const expenseColumns = (
  openModalHandler: (id: string) => void,
  updateCallback: (id: string) => void
): IExpenseColumns[] => [
  {
    field: 'title',
    label: 'Title',
    cellRender: ({label}) => label
  },
  {
    field: 'amount',
    label: 'Amount',
    cellRender: ({amount}) => (
      <>£{`${amount ? (+amount).toFixed(2) : '0.00'}`}</>
    )
  },
  {
    field: 'date',
    label: 'Date',
    cellRender: ({date}) => format(new Date(date!), 'dd/MM/yyyy')
  },
  {
    field: 'who',
    label: 'Who',
    cellRender: ({whoseExpense}) => whoseExpense
  },
  {
    field: 'hasPaid',
    label: 'Has Paid?',
    cellRender: ({paid, id}) => (
      <div
        className={paid ? styles.paid : styles.notPaid}
        onClick={() => updateCallback(id!)}
      >
        {paid ? (
          <Icon name="circleCheck" size="lg" />
        ) : (
          <Icon name="close" size="lg" color="secondary" />
        )}
      </div>
    )
  },
  {
    field: 'actions',
    label: '',
    cellRender: ({id}) =>
      id ? (
        <div className={styles.trash} onClick={() => openModalHandler(id!)}>
          <Icon name="trash" />
        </div>
      ) : null
  }
]

export function ExpenseList({
  expenses,
  removeCallback,
  updateCallback
}: IExpenseListProps) {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedId, setSelectedId] = useState<null | string>(null)
  const [filter, setFilter] = useState<null | string>(null)

  const handleFilterChange = (filterBy: string) => {
    setFilter(filterBy)
  }
  const openModalHandler = (id: string) => {
    setIsOpen(true)
    setSelectedId(id)
  }
  const removeExpense = async (id: string) => {
    console.log('id: ', id)
    removeCallback(id)
    setIsOpen(false)
  }
  const filteredExpenses =
    filter && filter !== 'all'
      ? expenses.filter((e) => e.whoseExpense === filter)
      : expenses

  return (
    <div className={styles.expenseListContainer}>
      <StatusBar expenses={expenses} />
      <div className={styles.filterWrapper}>
        <label>Filter By: </label>
        <select onChange={(e) => handleFilterChange(e.target.value)}>
          <option value="all">All</option>
          <option value="dad">Daddy</option>
          <option value="mum">Mummy</option>
          <option value="joint">Joint</option>
        </select>
      </div>
      {filteredExpenses.length > 0 && (
        <DataTable
          data={filteredExpenses}
          columns={expenseColumns(openModalHandler, updateCallback)}
        />
      )}
      {isOpen && (
        <Modal
          title="Delete Item?"
          onClose={() => setIsOpen(false)}
          content={<p>Are you sure you want to remove this?</p>}
          buttons={[
            {
              label: 'Cancel',
              color: 'secondary',
              onClick: () => setIsOpen(false)
            },
            {
              label: 'Confirm',
              color: 'primary',
              onClick: () => removeExpense(selectedId || '')
            }
          ]}
        />
      )}
    </div>
  )
}

function StatusBar({expenses}: {expenses: IExpenseDetail[]}) {
  const expenseTotal = expenses.length
    ? expenses.reduce((acc, expense) => acc + +expense.amount!, 0)
    : 0
  const statusResult = calculateStatus(expenseTotal)

  return (
    <div
      className={classnames(styles.statusBar, statusResult.style)}
    >{`Status ${statusResult.message}`}</div>
  )
}

function calculateStatus(sum: number) {
  if (sum < +WARNING_LIMIT) {
    return {
      style: styles.good,
      message: `Good: You have ${(+WARNING_LIMIT - sum).toFixed(
        2
      )} left to reach warning!`
    }
  }

  if (sum >= +WARNING_LIMIT && sum < +DANGER_LIMIT) {
    return {
      style: styles.warning,
      message: `Warning: You have ${(+DANGER_LIMIT - sum).toFixed(
        2
      )} left to reach danger!`
    }
  }

  return {style: styles.danger, message: `Danger: Try not to spend any more!`}
}
