import React from 'react'
import {useParams} from 'react-router-dom'
import {useQueries, useQueryClient, useMutation} from 'react-query'
import {
  Button,
  DataTable,
  Loading,
  Modal,
  TextInput
} from '@ryusenpai/shared-components'
import {NavMenu, INavMenuItem} from '@ryusenpai/shared-components'

import {homeMediaApi} from '../../api/homeMediaApi'
import {allowedLangs, moviesColumns} from './constants'
import type {IVideoDto, Lang} from '../../api/types'

import styles from './index.module.scss'

const filters = [
  '1981 - 1985',
  '1986 - 1990',
  '1991 - 1995',
  '1996 - 2000',
  '2001 - 2005',
  '2006 - 2010',
  '2011 - 2015',
  '2016 - 2020',
  '2021 - 2025'
]

export function MovieList() {
  const [selectedYearPair, setSelectedYearPair] = React.useState(filters[0])
  const [modalIsOpen, setModalIsOpen] = React.useState(false)
  const [selectedItem, setSelectedItem] = React.useState<IVideoDto | null>(null)
  const [searchKeyword, setSearchKeyword] = React.useState('')

  const [yearFrom, yearTo] = selectedYearPair.split(' - ')
  const queryClient = useQueryClient()
  const params = useParams()
  const {lang} = params

  if (!params.lang || !allowedLangs.includes(lang as Lang)) {
    return <>This page is not valid</>
  }

  const [initialData, searchResult] = useQueries([
    {
      queryKey: ['list', {yearFrom, yearTo, lang: params.lang}],
      queryFn: homeMediaApi.getMovies
    },
    {
      queryKey: ['list', {searchKeyword, lang: params.lang}],
      queryFn: homeMediaApi.searchMovies,
      enabled: !!searchKeyword
    }
  ])

  const deleteMutation = useMutation({
    mutationFn: homeMediaApi.deleteMovieEntry,
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['list']})
    }
  })
  const {data: list} = initialData

  //console.log('result: ', initialData)
  if (initialData.isLoading || !initialData.data) {
    return <Loading />
  }

  const items = filters.map((m) => ({label: m}))
  const handleClick = (navItem: INavMenuItem) => {
    setSearchKeyword('')
    setSelectedYearPair(navItem.label)
  }
  const openModalHandler = (id: number) => {
    const foundItem = list.data.find((d: IVideoDto) => d.id === id)
    if (foundItem) {
      setSelectedItem(foundItem)
      setModalIsOpen(true)
    }
  }

  const closeModalHandler = () => {
    setModalIsOpen(false)
    setSelectedItem(null)
  }

  const handleDelete = () => {
    if (selectedItem) {
      try {
        deleteMutation.mutate({id: selectedItem.id, lang: lang as Lang})
        console.log('id: ', selectedItem.id)
        setSelectedItem(null)
        setModalIsOpen(false)
      } catch (e) {
        console.log('Cannot delete item')
      }
    }
  }

  const searchHandler = (e: React.FormEvent, value: string) => {
    e.preventDefault()
    setSearchKeyword(value)
  }

  //console.log('Search Result: ', searchResult)
  return (
    <>
      <div className={styles.moviesContainer}>
        <NavMenu
          items={items}
          onClick={handleClick}
          selected={{label: selectedYearPair}}
        />
        <SearchBar submitHandler={searchHandler} />
        <DataTable
          data={searchResult?.data || list.data}
          columns={moviesColumns(openModalHandler)}
        />
        <div>No. of items: {list.data.length}</div>
      </div>
      {modalIsOpen && (
        <Modal
          title="Delete Item"
          content={
            <>
              {selectedItem ? (
                <>
                  <p>{selectedItem?.title}</p>
                  <p>Are you sure want to delete this?</p>
                </>
              ) : (
                <>Error: no selected item</>
              )}
            </>
          }
          onClose={closeModalHandler}
          buttons={[
            {
              label: 'Cancel',
              color: 'secondary',
              onClick: closeModalHandler
            },
            {
              label: 'Confirm',
              color: 'primary',
              onClick: handleDelete
            }
          ]}
        />
      )}
    </>
  )
}

function SearchBar({
  submitHandler
}: {
  submitHandler: (e: React.FormEvent, value: string) => void
}) {
  const [keyword, setKeyword] = React.useState<string>('')

  return (
    <form
      onSubmit={(e) => submitHandler(e, keyword)}
      className={styles.searchWrapper}
    >
      <TextInput
        label="search"
        hideLabel
        onChange={(e) => setKeyword(e.target.value)}
      />
      <Button label="search" type="submit" />
    </form>
  )
}
