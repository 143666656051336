// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".kcG7ojEkdStUKMCJUqk8Tg\\=\\={display:flex}.qdHRUQsoznqygQR-ZBC3qA\\=\\={margin-bottom:15px;position:relative}._7N3EZc-23c9Hu4CbTpw52Q\\=\\={border:1px solid #000;position:absolute;background-color:#fff;left:150px;top:30px;z-index:1}._8y93i2K6VJJJrNAsV7Nxlw\\=\\={display:inline-block;margin-bottom:10px}.Zy0fgF89A7nCXXcteZdZ1g\\=\\={margin-bottom:10px}.Zy0fgF89A7nCXXcteZdZ1g\\=\\= button{margin-right:10px}@media(max-width: 768px){.qdHRUQsoznqygQR-ZBC3qA\\=\\={display:flex;flex-direction:column;justify-content:center;align-items:center;text-align:center}}", "",{"version":3,"sources":["/home/momo/sites/expenses/src/main/expenses/addExpense.module.scss"],"names":[],"mappings":"AAAA,4BACE,YAAA,CAGF,4BACE,kBAAA,CACA,iBAAA,CAGF,6BACE,qBAAA,CACA,iBAAA,CACA,qBAAA,CACA,UAAA,CACA,QAAA,CACA,SAAA,CAGF,6BACE,oBAAA,CACA,kBAAA,CAEF,4BACE,kBAAA,CAGF,mCACE,iBAAA,CAGF,yBACE,4BACE,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,kBAAA,CACA,iBAAA,CAAA","file":"addExpense.module.scss","sourcesContent":[".whoExpenseContainer {\n  display: flex;\n}\n\n.addExpenseContainer {\n  margin-bottom: 15px;\n  position: relative;\n}\n\n.datePickerWrapper {\n  border: 1px solid black;\n  position: absolute;\n  background-color: white;\n  left: 150px;\n  top: 30px;\n  z-index: 1;\n}\n\n.label {\n  display: inline-block;\n  margin-bottom: 10px;\n}\n.whoseExpenseBtnWrapper {\n  margin-bottom: 10px;\n}\n\n.whoseExpenseBtnWrapper button {\n  margin-right: 10px;\n}\n\n@media (max-width: 768px) {\n  .addExpenseContainer {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    text-align: center;\n  }\n}\n"]}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"whoExpenseContainer": "kcG7ojEkdStUKMCJUqk8Tg==",
	"addExpenseContainer": "qdHRUQsoznqygQR-ZBC3qA==",
	"datePickerWrapper": "_7N3EZc-23c9Hu4CbTpw52Q==",
	"label": "_8y93i2K6VJJJrNAsV7Nxlw==",
	"whoseExpenseBtnWrapper": "Zy0fgF89A7nCXXcteZdZ1g=="
};
export default ___CSS_LOADER_EXPORT___;
