import React from 'react'
import classnames from 'classnames'

import styles from './index.module.scss'

export interface INavMenuItem {
  label: string
}

export interface INavMenuProps {
  items: INavMenuItem[]
  onClick: (item: INavMenuItem) => void
  selected?: INavMenuItem | null
  disabled?: INavMenuItem[]
}

export function NavMenu({
  items,
  onClick,
  selected = null,
  disabled = []
}: INavMenuProps) {
  //const [selectedItem, setSelectedItem] = React.useState<null | INavMenuItem>(
    //selected
  //)

  const handleItemClick = (item: INavMenuItem) => {
    //setSelectedItem(item)
    onClick && onClick(item)
  }

  return (
    <ul className={classnames('NavMenuComponent', styles.navMenuWrapper)}>
      {items.map((i) => {
        const isDisabled = disabled.find((d) => d.label === i.label)
        const isSelected =
          selected && i.label === selected.label ? styles.selected : ''

        const combinedCss = classnames(
          isSelected,
          isDisabled ? styles.disabled : ''
        )
        return (
          <li
            key={i.label}
            onClick={isDisabled ? () => {} : () => handleItemClick(i)}
            className={combinedCss}
          >
            {i.label}
          </li>
        )
      })}
    </ul>
  )
}
