// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._5ntBCyDgtAKgf799MN3-7g\\=\\={text-align:center;margin:3rem auto;width:60%}", "",{"version":3,"sources":["/home/momo/sites/expenses/src/main/user/login.module.scss"],"names":[],"mappings":"AAAA,6BACE,iBAAA,CACA,gBAAA,CACA,SAAA","file":"login.module.scss","sourcesContent":[".loginContainer {\n  text-align: center;\n  margin: 3rem auto;\n  width: 60%;\n}\n"]}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loginContainer": "_5ntBCyDgtAKgf799MN3-7g=="
};
export default ___CSS_LOADER_EXPORT___;
