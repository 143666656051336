import React, {useState} from 'react'
import classnames from 'classnames'

import styles from './index.module.scss'

interface ITextInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string
  variant?: 'default' | 'primary'
  fullWidth?: boolean
  hideLabel?: boolean
}

export function TextInput({
  value,
  label,
  onChange,
  className,
  variant = 'default',
  fullWidth = false,
  hideLabel = false
}: ITextInputProps) {
  const [isActive, setIsActive] = useState(false)

  return (
    <div
      className={classnames(
        'TextInputComponent',
        styles.inputContainer,
        {
          [styles.fullWidth]: fullWidth,
          [styles.containerPrimary]: variant === 'primary'
        },
        className
      )}
    >
      {hideLabel ? null : (
        <label
          className={classnames(
            variant === 'primary' ? styles.labelPrimary : styles.defaultLabel,
            isActive && styles.isActive
          )}
          htmlFor={label}
        >
          {label}
        </label>
      )}
      <input
        id={label}
        type="text"
        value={value}
        placeholder={!isActive && variant !== 'primary' ? label : ''}
        onFocus={() => {
          setIsActive(true)
        }}
        onBlur={() => {
          setIsActive(false)
        }}
        onChange={onChange}
      />
    </div>
  )
}
