import React from 'react'
import classnames from 'classnames'

import {Icon} from '../../nano/icon'
import {Button} from '../../nano/button'

import styles from './index.module.scss'

export interface IModalProps {
  title: string
  onClose: () => void
  content: React.ReactNode
  buttons?: Array<{
    label: string
    color: 'primary' | 'secondary'
    onClick: () => void
  }>
}

export function Modal({title, onClose, buttons = [], content}: IModalProps) {
  return (
    <div className={classnames('ModalComponent', styles.modalContainer)}>
      <div className={styles.overlay}>
        <div className={styles.header}>
          <div>{title}</div>
          <Icon
            name="close"
            color="secondary"
            onClick={onClose}
            className={styles.closeBtn}
          />
        </div>
        <div>{content}</div>
        {buttons.length > 0 && (
          <div className={styles.footer}>
            {buttons.map((b) => (
              <Button
                key={b.label}
                primary={b.color === 'primary'}
                onClick={b.onClick}
                label={b.label}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  )
}
