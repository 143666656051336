// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".xXhAyl0AtF\\+NQ6sO9ZWCFw\\=\\=>div{display:inline-flex;padding:var(--space-xs)}", "",{"version":3,"sources":["/home/momo/sites/expenses/src/common/components/searchBar.module.scss"],"names":[],"mappings":"AACE,iCACE,mBAAA,CACA,uBAAA","file":"searchBar.module.scss","sourcesContent":[".searchWrapper {\n  & > div {\n    display: inline-flex;\n    padding: var(--space-xs);\n  }\n}\n"]}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchWrapper": "xXhAyl0AtF+NQ6sO9ZWCFw=="
};
export default ___CSS_LOADER_EXPORT___;
