import React from 'react'
import {Button} from '@ryusenpai/shared-components'

import type {Lang} from '../../api/types'
import type {ITableColumn, IVideoDto, ISeriesDto} from './types'

export const allowedLangs: Lang[] = ['en', 'hk']

export const moviesColumns = (
  openModalHandler: (id: number) => void
): ITableColumn<IVideoDto>[] => [
  {
    field: 'title',
    label: 'Title',
    cellRender: ({title}) => title
  },
  {
    field: 'quality',
    label: 'Quality',
    cellRender: ({quality}) => quality
  },
  {
    field: 'size',
    label: 'File Size',
    cellRender: ({size}) => size
  },
  {
    field: 'year',
    label: 'Year',
    cellRender: ({year}) => year
  },
  {
    field: 'location',
    label: 'Location',
    cellRender: ({location}) => location
  },
  {
    field: 'actions',
    label: '',
    cellRender: ({id}) => (
      <Button label="Delete" onClick={() => openModalHandler(id)} />
    )
  }
]

export const seriesColumns = (
  handleDelete: (id: number) => void
): ITableColumn<ISeriesDto>[] => [
  {
    field: 'title',
    label: 'Title',
    cellRender: ({title}) => title
  },
  {
    field: 'quality',
    label: 'Quality',
    cellRender: ({quality}) => quality
  },
  {
    field: 'noOfEpisodes',
    label: 'Number of Episodes',
    cellRender: ({num_of_episodes}) => num_of_episodes
  },
  {
    field: 'actions',
    label: '',
    cellRender: ({id}) => (
      <Button label="Delete" onClick={() => handleDelete(id)} />
    )
  }
]
