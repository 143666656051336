import React, {useState} from 'react'
import classnames from 'classnames'

import styles from './index.module.scss'
import {Icon} from '../icon'

export interface ICheckboxProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string
  isChecked: boolean
  onChangeHandler: (isChecked: boolean) => void
}

export function Checkbox({label, isChecked, onChangeHandler}: ICheckboxProps) {
  const [isItChecked, setIsItChecked] = useState(false)

  React.useEffect(() => {
    if (isChecked !== isItChecked) {
      setIsItChecked(isChecked)
    }
  }, [isChecked, isItChecked])

  const handleCheckboxClick = () => {
    setIsItChecked((prevState) => {
      const newState = !prevState
      onChangeHandler(newState)
      return newState
    })
  }

  return (
    <div
      className={classnames('CheckboxComponent', styles.checkboxFieldWrapper)}
    >
      <input
        type="checkbox"
        name={label}
        checked={isItChecked}
        onChange={handleCheckboxClick}
        readOnly
        id={label}
      />
      <div className={styles.checkboxWrapper} onClick={handleCheckboxClick}>
        <span className={styles.styledCheckbox} />
        {isItChecked && <Icon name="check" color="blank" size="sm" />}
      </div>
      <label htmlFor={label}>{label}</label>
    </div>
  )
}
