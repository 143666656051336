// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".v0RECnhg8C46ZooxfUTtfw\\=\\= {\n  margin-bottom: 10px;\n}\n\n.lWNx4yPLdR86zDyhWBomug\\=\\= {\n  display: block;\n  margin-bottom: 10px;\n}\n\n.\\+5dswvSZi8xe9Ro4rRp21g\\=\\= {\n  padding: 5px;\n  border-radius: 5px;\n}\n", "",{"version":3,"sources":["index.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,kBAAkB;AACpB","file":"index.module.css","sourcesContent":[".formRow {\n  margin-bottom: 10px;\n}\n\n.label {\n  display: block;\n  margin-bottom: 10px;\n}\n\n.textInput {\n  padding: 5px;\n  border-radius: 5px;\n}\n"]}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formRow": "v0RECnhg8C46ZooxfUTtfw==",
	"label": "lWNx4yPLdR86zDyhWBomug==",
	"textInput": "+5dswvSZi8xe9Ro4rRp21g=="
};
export default ___CSS_LOADER_EXPORT___;
