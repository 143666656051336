import React from 'react'
import classnames from 'classnames'

import styles from './index.module.scss'

export interface ITableColumn<T> {
  field: string
  label: string
  cellRender: (data: T) => React.ReactNode
}

export interface IDataTableProps<T> {
  data: T[]
  columns: Array<ITableColumn<T>>
  rowRender?: (data: T) => React.ReactNode
  fullWidth?: boolean
}

export function DataTable({
  data,
  columns,
  rowRender,
  fullWidth = false
}: IDataTableProps<any>) {
  return (
    <table
      className={classnames(
        'TableComponent',
        styles.tableContainer,
        fullWidth && styles.fullWidth
      )}
    >
      <thead>
        <tr>
          {columns.map((item) => (
            <th key={item.field}>{item.label}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rowRender
          ? rowRender(data)
          : data.map((rowData: {id: number | string} & any) => (
              <tr key={rowData.id}>
                {columns.map((c) => (
                  <td key={c.field}>{c.cellRender(rowData)}</td>
                ))}
              </tr>
            ))}
      </tbody>
    </table>
  )
}
