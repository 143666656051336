import React from 'react'
import classnames from 'classnames'

import styles from './index.module.scss'

export function Loading() {
  return (
    <div className={classnames('LoadingComponent', styles.loadingWrapper)}>
      <span className={styles.loadingContent}>Loading</span>
    </div>
  )
}
