import React from 'react'
import {createRoot} from 'react-dom/client'
import {RouterProvider} from 'react-router-dom'
import {QueryClient, QueryClientProvider} from 'react-query'

import {router} from './main/routes'

// @ts-ignore
if (module.hot) {
  // @ts-ignore
  module.hot.accept()
}

const root = createRoot(document.getElementById('root') as HTMLElement)

const queryClient = new QueryClient()

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  </React.StrictMode>
)
