// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".oi-qBYUlGDajUIj3XEbdDQ\\=\\={display:flex;gap:.5rem;flex-wrap:wrap;text-align:left}.oi-qBYUlGDajUIj3XEbdDQ\\=\\=>div{flex:1;display:flex;flex-direction:column;justify-content:space-between;min-width:fit-content}._8ZSir1rdW-VoSHdV5fj7WA\\=\\={background-color:#fff;border:1px solid #000;border-radius:10px;padding:var(--space-sm)}._40I9FgireaVfsu4q8pevEA\\=\\={justify-content:space-between;background-color:var(--color-secondary-dark);color:#fff;padding:var(--space-sm);border-radius:10px}.sr60HqR-J\\+3ZWu1qsC03yA\\=\\={background-color:var(--color-success);color:#fff;padding:var(--space-sm);border-radius:10px}", "",{"version":3,"sources":["/home/momo/sites/expenses/src/main/expenses/expenseTotals.module.scss"],"names":[],"mappings":"AAAA,4BACE,YAAA,CACA,SAAA,CACA,cAAA,CAEA,eAAA,CAEA,gCACE,MAAA,CACA,YAAA,CACA,qBAAA,CACA,6BAAA,CACA,qBAAA,CAIJ,6BACE,qBAAA,CACA,qBAAA,CAEA,kBAAA,CACA,uBAAA,CAGF,6BACE,6BAAA,CACA,4CAAA,CACA,UAAA,CAEA,uBAAA,CACA,kBAAA,CAEF,6BAIE,qCAAA,CACA,UAAA,CAEA,uBAAA,CAEA,kBAAA","file":"expenseTotals.module.scss","sourcesContent":[".expenseTotalContainer {\n  display: flex;\n  gap: 0.5rem;\n  flex-wrap: wrap;\n  //flex-direction: column;\n  text-align: left;\n\n  & > div {\n    flex: 1;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    min-width: fit-content;\n  }\n}\n\n.expenseTotal {\n  background-color: white;\n  border: 1px solid black;\n\n  border-radius: 10px;\n  padding: var(--space-sm);\n}\n\n.notPaidTotal {\n  justify-content: space-between;\n  background-color: var(--color-secondary-dark);\n  color: white;\n\n  padding: var(--space-sm);\n  border-radius: 10px;\n}\n.paidTotal {\n  //display: flex;\n  //justify-content: space-between;\n\n  background-color: var(--color-success);\n  color: white;\n\n  padding: var(--space-sm);\n  //margin-bottom: var(--space-xs);\n  border-radius: 10px;\n}\n"]}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"expenseTotalContainer": "oi-qBYUlGDajUIj3XEbdDQ==",
	"expenseTotal": "_8ZSir1rdW-VoSHdV5fj7WA==",
	"notPaidTotal": "_40I9FgireaVfsu4q8pevEA==",
	"paidTotal": "sr60HqR-J+3ZWu1qsC03yA=="
};
export default ___CSS_LOADER_EXPORT___;
