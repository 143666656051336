import React from 'react'
import {
  CartesianGrid,
  LineChart,
  XAxis,
  YAxis,
  Legend,
  Line,
  Tooltip
} from 'recharts'
import type { IMonthlyTotalViewDto } from "../../api/types";

export function YearlyExpenseChart({data}: {data: IMonthlyTotalViewDto[]}) {
  return (
    <LineChart width={730} height={250} data={data}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Line type="monotone" dataKey="total" stroke="#8884d8" />
      <Line type="monotone" dataKey="jointTotal" stroke="#ffc658" />
      <Line type="monotone" dataKey="dadTotal" stroke="#82ca9d" />
      <Line type="monotone" dataKey="mumTotal" stroke="var(--color-secondary-light)" />
    </LineChart>
  )
}
