import React, {useState} from 'react'
import {DayPicker} from 'react-day-picker'
import {format} from 'date-fns'
import {Button, Checkbox, TextInput} from '@ryusenpai/shared-components'

import {TextInput as DateInput} from '../../common/components/textInput'
import {useOutsideClick} from '../../hooks/useOutsideClick'
import type {IExpenseDetail, ExpenseBelong} from '.'

import styles from './addExpense.module.scss'

interface IAddNewExpenseProps {
  datePickerDate: Date
  callbackFn: (expenseDetail: IExpenseDetail) => void
}

const today = new Date()
const defaultExpense = {
  date: null,
  label: '',
  amount: null,
  paid: false,
  whoseExpense: undefined
}

export function AddNewExpense({
  datePickerDate,
  callbackFn
}: IAddNewExpenseProps) {
  const [datePickerIsOpen, setDatePickerIsOpen] = useState(false)
  const [expenseDetail, setExpenseDetails] =
    useState<IExpenseDetail>(defaultExpense)
  const dateRef = React.useRef(null)

  useOutsideClick(dateRef, () => {
    setDatePickerIsOpen(false)
  })

  const onSelectDate = (date?: Date) => {
    setDatePickerIsOpen(false)
    if (date) {
      setExpenseDetails((prevState) => ({...prevState, date}))
    }
  }

  const titleOnChange = (value: string) => {
    setExpenseDetails((prevState) => ({...prevState, label: value}))
  }

  const addExpense = async () => {
    const {label, amount, date, whoseExpense} = expenseDetail
    if (!label || !amount || !date || !whoseExpense) {
      return
    }
    console.log('expenseDetail: ', expenseDetail)
    callbackFn(expenseDetail)
    setExpenseDetails(defaultExpense)
  }

  const amountOnChange = (value: string) => {
    if (!isValidAmountFormat(value)) {
      return
    }

    setExpenseDetails((prevState) => ({...prevState, amount: value}))
  }

  const isPaidChangeHandler = () => {
    setExpenseDetails((prevState) => ({...prevState, paid: !prevState.paid}))
  }

  const onSelectWhoseExpense = (value: ExpenseBelong) => {
    setExpenseDetails((prevState) => ({...prevState, whoseExpense: value}))
  }

  return (
    <div className={styles.addExpenseContainer}>
      <DateInput
        label="date"
        readOnly
        onFocus={() => setDatePickerIsOpen(true)}
        value={
          expenseDetail.date
            ? format(expenseDetail.date as Date, 'dd/MM/yyyy')
            : ''
        }
      />
      {datePickerIsOpen && (
        <div className={styles.datePickerWrapper} ref={dateRef}>
          <DayPicker
            mode="single"
            onSelect={onSelectDate}
            fromMonth={datePickerDate}
            toMonth={datePickerDate}
            disabled={{after: today}}
          />
        </div>
      )}
      <TextInput
        value={expenseDetail.label}
        variant="primary"
        label="Title/Shop"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          titleOnChange(e.target.value)
        }
      />
      <TextInput
        value={expenseDetail.amount || ''}
        variant="primary"
        label="Amount"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          amountOnChange(e.target.value)
        }
      />
      <br />
      <WhoseExpense
        value={expenseDetail.whoseExpense}
        onClick={onSelectWhoseExpense}
      />
      <Checkbox
        isChecked={expenseDetail.paid}
        label="Paid?"
        onChangeHandler={isPaidChangeHandler}
      />
      <br />
      <div>
        <Button label="Add" primary onClick={addExpense} />
      </div>
    </div>
  )
}

interface IWhoseExpenseProps {
  onClick: (value: ExpenseBelong) => void
  value?: ExpenseBelong
}

function WhoseExpense({onClick, value}: IWhoseExpenseProps) {
  return (
    <div>
      <span className={styles.label}>Whose expense?</span>
      <div className={styles.whoseExpenseBtnWrapper}>
        <Button
          backgroundColor={value === 'dad' ? 'var(--color-primary-light)' : ''}
          primary
          onClick={() => onClick('dad')}
          label="Daddy"
        />
        <Button
          backgroundColor={value === 'mum' ? 'var(--color-primary-light)' : ''}
          primary
          onClick={() => onClick('mum')}
          label="Mummy"
        />
        <Button
          backgroundColor={
            value === 'joint' ? 'var(--color-primary-light)' : ''
          }
          primary
          onClick={() => onClick('joint')}
          label="Joint"
        />
      </div>
    </div>
  )
}

function isValidAmountFormat(input: string): boolean {
  const regex = /^\d*\.?\d*$/

  return regex.test(input)
}
