import React from 'react'
import classnames from 'classnames'

import styles from './index.module.scss'

export interface IPaginationProps {
  total: number
  itemsPerPage: number
  onClick: (index: number) => void
  selected?: number
}

export function Pagination({
  total,
  itemsPerPage,
  onClick,
  selected
}: IPaginationProps) {
  const [isActive, setIsActive] = React.useState<number>(selected ?? 0)
  const handleOnClick = (index: number) => {
    setIsActive(index)
    onClick(index)
  }

  const numberOfPages = Math.ceil(total / itemsPerPage)
  const pageNumbersArray = Array(numberOfPages).fill(numberOfPages)

  return (
    <ul className={classnames('PaginationComponent', styles.pagingWrapper)}>
      {pageNumbersArray.map((_, index) => (
        <li
          key={index}
          className={isActive === index ? styles.active : ''}
          onClick={() => handleOnClick(index)}
        >
          {index + 1}
        </li>
      ))}
    </ul>
  )
}
