import React from 'react'
import {Button, TextInput} from '@ryusenpai/shared-components'

import styles from './searchBar.module.scss'

export function SearchBar({
  submitHandler
}: {
  submitHandler: (e: React.FormEvent, value: string) => void
}) {
  const [keyword, setKeyword] = React.useState<string>('')

  return (
    <form
      onSubmit={(e) => submitHandler(e, keyword)}
      className={styles.searchWrapper}
    >
      <TextInput
        label="search"
        hideLabel
        onChange={(e) => setKeyword(e.target.value)}
      />
      <Button label="search" type="submit" />
    </form>
  )
}
