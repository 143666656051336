// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js!../../node_modules/@ryusenpai/shared-components/dist/style.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":root{--space-small: 8px;--space-medium: 12px;--space-large: 18px;--space-x-large: 24px;--primary-color: #52BE80;--secondary-color: #5DADE2}.M5dT8-MCXL0y73M8JtXL7Q\\=\\={font-family:\"Roboto\";padding:1rem}@media(max-width: 767px){.M5dT8-MCXL0y73M8JtXL7Q\\=\\={padding:0}}", "",{"version":3,"sources":["/home/momo/sites/expenses/src/main/index.module.scss"],"names":[],"mappings":"AAGA,MACE,kBAAA,CACA,oBAAA,CACA,mBAAA,CACA,qBAAA,CACA,wBAAA,CACA,0BAAA,CAQF,4BACE,oBAAA,CACA,YAAA,CAGF,yBACE,4BACE,SAAA,CAAA","file":"index.module.scss","sourcesContent":["//@import \"@ryusenpai/shared-components/dist/src/global.module.scss\";\n@import \"@ryusenpai/shared-components/dist/style.css\";\n\n:root {\n  --space-small: 8px;\n  --space-medium: 12px;\n  --space-large: 18px;\n  --space-x-large: 24px;\n  --primary-color: #52BE80;\n  --secondary-color: #5DADE2;\n}\n\nbody {\n  //background-color: #f2f2f2;\n  //background-color: silver;\n}\n\n.main {\n  font-family: 'Roboto';\n  padding: 1rem;\n}\n\n@media (max-width: 767px) {\n  .main {\n    padding: 0;\n  }\n}\n"]}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "M5dT8-MCXL0y73M8JtXL7Q=="
};
export default ___CSS_LOADER_EXPORT___;
