import React, {useEffect, useState, useCallback} from 'react'
import {format} from 'date-fns'
import {
  Button,
  Modal,
  NavMenu,
  INavMenuItem
} from '@ryusenpai/shared-components'

import {ExpenseList} from './expenseList'
import {ExpenseTotals} from './expenseTotals'
import {AddNewExpense} from './addExpense'
import {YearlyExpenseChart} from './chart'
import {monthNames} from '../constants'
import api from '../../api'

import 'react-day-picker/dist/style.css'
import styles from './expenses.module.css'
import type { IMonthlyTotalViewDto } from "../../api/types";

const today = new Date()
const year = today.getFullYear()
const currentMonth = today.getMonth()

export type ExpenseBelong = 'dad' | 'mum' | 'joint'

export interface IExpenseDetail {
  id?: string
  date: null | string | Date
  label: string
  amount: null | string
  paid: boolean
  whoseExpense?: ExpenseBelong
}

export function Expenses() {
  const [selectedYear, setSelectedYear] = useState(year)
  const [selectedMonth, setSelectedMonth] = useState(currentMonth)
  const [datePickerDate, setDatePickerDate] = useState(today)
  const [expenseList, setExpenseList] = useState<IExpenseDetail[]>([])
  const [errorMsg, setErrorMsg] = useState<null | string>(null)
  const [chartIsOpen, setChartIsOpen] = useState(false)
  const [monthlyTotals, setMonthlyTotals] = useState<IMonthlyTotalViewDto[]>([])

  useEffect(() => {
    const fetchExpenses = async () => {
      const selectedMonth = format(datePickerDate, 'LL')
      //const selectedYear = format(datePickerDate, 'yyyy')
      const expenseDetail = await api.getExpensesForMonth(
        +selectedMonth,
        +selectedYear
      )
      if (expenseDetail) {
        setExpenseList(expenseDetail as any)
      }
    }
    fetchExpenses()
  }, [datePickerDate, selectedYear])

  useEffect(() => {
    const fetchMonthlyTotals = async () => {
      const result = await api.getMonthlyTotals(selectedYear)
      setMonthlyTotals(result as IMonthlyTotalViewDto[])
    }
    fetchMonthlyTotals()
  }, [selectedYear])

  const handleChangeYear = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const changedYear = +e.target.value
    setSelectedYear(changedYear)
    const revisedMonth =
      selectedMonth > currentMonth && year === changedYear ? 0 : selectedMonth

    setSelectedMonth(revisedMonth)
    setDatePickerDate(new Date(changedYear, revisedMonth, 1))
  }

  const onMonthClickCallback = (month: number) => {
    setSelectedMonth(month)
    const newDate = new Date(selectedYear, month, 1)
    setDatePickerDate(newDate)
  }

  const addExpenseCallback = async (expenseDetail: IExpenseDetail) => {
    if (expenseDetail && expenseDetail.date) {
      console.log('add expense: ', expenseDetail)
      try {
        const {data: addResult, error} = await api.addExpense({
          ...expenseDetail,
          date: formatDate(expenseDetail.date as string)
        })
        if (addResult) {
          return setExpenseList([...expenseList, ...addResult])
        }
        return setErrorMsg(error || 'Something went wrong')
      } catch (e) {
        setErrorMsg('Cannot add expense')
      }
    }
  }

  const updateExpenseCallback = async (id: string) => {
    let foundExpense
    const updatedExpenses = expenseList.map((e) => {
      if (e.id === id) {
        foundExpense = {
          ...e,
          paid: !e.paid,
          date: formatDate(e.date as string)
        }
        return foundExpense
      }
      return e
    })

    if (foundExpense) {
      try {
        await api.updateExpense(foundExpense)
        setExpenseList(updatedExpenses)
      } catch (e) {
        setErrorMsg('Cannot update expense')
      }
    }
  }

  const removeExpenseCallback = useCallback(
    async (id: string) => {
      const filteredExpenses = expenseList.filter((e) => e.id !== id)
      await api.removeExpense(id)
      setExpenseList(filteredExpenses)
    },
    [expenseList]
  )

  return (
    <div className={styles.appContainer}>
      Today's date: {format(today, 'dd/MM')}/
      <select onChange={handleChangeYear}>
        <option>{year}</option>
        <option>{year - 1}</option>
        <option>{year - 2}</option>
      </select>
      ITS ON PROD
      <Months
        selectedYear={selectedYear}
        selectedMonth={selectedMonth}
        callbackFn={onMonthClickCallback}
      />
      <div>
        <Button label="Open Chart" onClick={() => setChartIsOpen(true)} />
      </div>
      <div className={styles.contentContainer}>
        <div>
          <AddNewExpense
            datePickerDate={datePickerDate}
            callbackFn={addExpenseCallback}
          />
          {errorMsg}
        </div>
        <div>
          <ExpenseList
            expenses={expenseList}
            removeCallback={removeExpenseCallback}
            updateCallback={updateExpenseCallback}
          />
          <ExpenseTotals expenses={expenseList} />
        </div>
      </div>
      {chartIsOpen && (
        <Modal
          title="Yearly Expense"
          onClose={() => setChartIsOpen(false)}
          content={<YearlyExpenseChart data={monthlyTotals} />}
        />
      )}
    </div>
  )
}

interface IMonthProps {
  callbackFn: (month: number) => void
  selectedYear: number
  selectedMonth: number
}

function Months({callbackFn, selectedMonth, selectedYear}: IMonthProps) {
  const handleNavItemClick = (item: INavMenuItem) => {
    const monthIndex = monthNames.findIndex((m) => m === item.label)
    const isBeyondCurrentMonth =
      //selectedYear > year && monthIndex > selectedMonth
      isBeyondCurrentMonthAndYear(monthIndex, selectedYear)
    if (isBeyondCurrentMonth) {
      return
    }
    callbackFn(monthIndex)
  }

  const months = monthNames.map((m) => ({label: m}))
  const selected = {label: monthNames[selectedMonth]}
  const monthsBeyondCurrentMonth = monthNames.filter(
    //(_, index) => index > selectedMonth && selectedYear >= year
    (_, monthIndex) => isBeyondCurrentMonthAndYear(monthIndex, selectedYear)
  )

  return (
    <>
      <div className={styles.monthsContainer}>
        <NavMenu
          items={months}
          selected={selected}
          onClick={handleNavItemClick}
          disabled={monthsBeyondCurrentMonth.map((m) => ({label: m}))}
        />
      </div>
    </>
  )
}

function formatDate(date: string) {
  const jsDate = new Date(date as string)
  return format(jsDate, 'yyyy-MM-dd')
}

function isBeyondCurrentMonthAndYear(
  selectedMonth: number,
  selectedYear: number
): boolean {
  //return selectedYear >= yearToCompare && selectedMonth > monthToCompare
  return selectedYear >= year && selectedMonth > currentMonth
}
